import React, { useState } from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import Layout from 'components/Layout'
import Seo from 'components/Seo'
import mq from 'styles/breakpoints'
import { GradientButton } from 'components/Subscriptions/PricingPlansComponents'

const SubmitContainer = styled.div`
  min-height: 85vh;
  margin: 5rem 30%;

  ${mq['xl']} {
    margin: 5rem 20%;
  }

  ${mq['lg']} {
    margin: 5rem 15%;
  }

  ${mq['md']} {
    margin: 5rem 10%;
  }

  ${mq['xsm']} {
    margin: 0 5%;
  }
`

const HeaderSection = styled.section``

const ContactSection = styled.section``

const TextContainer = styled.div`
  h2 {
    font-size: 3rem;
    padding-bottom: 5rem;
  }

  h4 {
    font-size: 2rem;
    text-align: center;
    padding-bottom: 3rem;
    letter-spacing: 0.3rem;
  }
`

const FormBody = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
`
const SubmitArea = styled.div``

const SupportInput = styled.input`
  flex-grow: 1;
  height: 3rem;
  margin-left: 1.5em;
  font-family: Roboto, Arial, sans-serif;
  padding: 0em 2em;
  display: inline-block;
  border: 1px solid #cccccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 1.5rem;
`
const SupportTextArea = styled.textarea`
  display: flex;
  margin-left: 1.5em;
  font-family: Roboto, Arial, sans-serif;
  padding: 0em 2em;
  display: inline-block;
  border: 1px solid #cccccc;
  border-radius: 4px;
  box-sizing: border-box;
  width: 100%;
  resize: none;
  font-size: 1.5rem;
  padding-top: 1rem;
`

const Support = () => {
  const { t } = useTranslation('supportPage')

  const [state, setState] = useState({})
  const [formSubmitted, setFormSubmitted] = useState(false)

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleContactSubmitted = () => {
    setFormSubmitted(true)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(handleContactSubmitted())
      .catch((error) => toast.error(error))
  }

  // helper to url encode form data
  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]),
      )
      .join('&')
  }

  return (
    <Layout>
      <Seo title={t('title')} description={t('description')} />
      <SubmitContainer>
        <HeaderSection>
          <TextContainer>
            <h2>{t('header')}</h2>
            <h4>{!formSubmitted ? t('contactUs') : t('formSubmitted')}</h4>
          </TextContainer>
        </HeaderSection>
        {!formSubmitted ? (
          <ContactSection>
            <form
              name="support"
              method="post"
              data-netlify="true"
              netlify-honeypot="zip"
              onSubmit={handleSubmit}
            >
              <FormBody>
                <Row>
                  <input type="hidden" name="form-name" value="support" />
                  <input type="hidden" name="zip" />
                  <SupportInput
                    type="text"
                    name="name"
                    placeholder={t('formName')}
                    onChange={handleChange}
                    required
                    maxLength="50"
                  />
                  <SupportInput
                    type="email"
                    name="email"
                    placeholder={t('formEmail')}
                    onChange={handleChange}
                    required
                    maxLength="320"
                  />
                </Row>
                <Row>
                  <SupportTextArea
                    name="comments"
                    placeholder={t('formMessage')}
                    rows="5"
                    onChange={handleChange}
                    required
                    maxLength="500"
                  />
                </Row>
                <SubmitArea>
                  <GradientButton type="submit">
                    {t('formSubmit')}
                  </GradientButton>
                </SubmitArea>
              </FormBody>
            </form>
          </ContactSection>
        ) : null}
      </SubmitContainer>
    </Layout>
  )
}

export default Support
